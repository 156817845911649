<template>
  <div class="col-10 sm:col-7 md:col-5 lg:col-4 xl:col-3">
    <Card>
      <template #header>
        <h1 class="display-2 text-center">Mot de passe oublié</h1>
      </template>
      <template #content>
        <p class="text-sm text-center">
          Saisissez votre adresse email afin de recevoir un lien de
          réinitialisation de mot de passe
        </p>
        <form id="form" @submit.prevent="onSubmit" class="p-field">
          <div class="p-fluid">
            <label for="email">Adresse email</label>
            <InputText id="email" type="email" v-model="email" />
          </div>
          <p v-if="error">{{ error }}</p>
        </form>
      </template>
      <template #footer>
        <div class="p-fluid">
          <Button
            label="Envoyer"
            icon="pi pi-envelope"
            type="submit"
            form="form"
            iconPos="right"
            :disabled="!email"
            :loading="pending"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref } from "vue";
import { projectAuth } from "../../firebase/config";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";

export default {
  setup() {
    const toast = useToast();
    const router = useRouter();

    const pending = ref(false);
    const error = ref(null);

    const onSubmit = async () => {
      pending.value = true;
      try {
        await projectAuth.sendPasswordResetEmail(email.value);
        await router.replace({ name: "Login" });
        toast.add({
          severity: "info",
          detail: "Email de récupération de mot de passe envoyé !",
          life: 2500,
        });
        error.value = null;
      } catch (err) {
        error.value = err.message;
      } finally {
        pending.value = false;
      }
    };

    const email = ref("");

    return { onSubmit, email, error, pending };
  },
};
</script>

<style lang="scss" scoped>
.p-card {
  border: none;
  box-shadow: none;
}
</style>
